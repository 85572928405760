import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import "./blogTemplate.css"

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark
  return (
    <Layout>

      <SEO title={frontmatter.title}
        description={frontmatter.description} />
      <div className="blog-detail-contrainer">
        <div className="blog-detail">
          {null != frontmatter.thumnail ? <div className="blog-detail-thumnail"><img alt={frontmatter.thumnail.name} src={frontmatter.thumnail.publicURL}></img></div> : ''}
          <div className="blog-detail-content">
            <h2 style={{ marginBottom: '5px' }}>{frontmatter.title}</h2>
            <p style={{ fontSize: '14px' }}>Posted on {frontmatter.date}</p>
            <div dangerouslySetInnerHTML={{ __html: html }}
          />
          </div>
        </div>
      </div>
    </Layout>
  )
}
export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "DD MMMM YYYY")
        path
        title
        description
        thumnail {
          id
          publicURL
          name
        }
      }
    }
  }
`